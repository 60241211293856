<template>
  <button
    :class="props.busy ? 'btn-loading' : null"
    :disabled="isDisabled"
    :type="props.type"
    class="btn relative"
  >
    <div
      :class="props.busy ? '' : 'opacity-0'"
      class="pointer-events-none absolute inset-0 flex items-center justify-center transition-opacity duration-100"
    >
      <BaseLoadingSpinner :class="spinnerClass" />
    </div>
    <span
      :class="props.busy ? 'opacity-0' : ''"
      class="transition-opacity duration-100"
    >
      <slot />
    </span>
  </button>
</template>

<script lang="ts" setup>
interface BaseAsyncButtonProps {
  busy?: boolean
  disabled?: boolean
  spinnerClass?: string
  type?: 'button' | 'submit'
}

const props = withDefaults(defineProps<BaseAsyncButtonProps>(), {
  busy: false,
  disabled: false,
  spinnerClass: 'text-current',
  type: 'button',
})

const isDisabled = computed(() => props.busy || props.disabled)
</script>
